<template>
    <div class="container_con">
        <el-card v-if="form" >
            <div class="con_title"><span></span> 采购计划</div>
            <div style="line-height: 24px; font-size: 14px;">
                <el-row :gutter="20" style="margin-bottom: 10px;">
                    <el-col :span="6">开票日期：{{form.purchase_plan_date}}</el-col>
                    <el-col :span="6">联系电话：{{form.merchant.mebl_operation_mode}}</el-col>
                    <el-col :span="6">部门：{{form.department_name}}</el-col>
                    <el-col :span="6">供货单位：{{form.merchant.merchant_name}}</el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="6">单据编号：{{form.purchase_plan_no}}</el-col>
                    <el-col :span="6">单位编号：{{form.merchant.merchant_no}}</el-col>
                    <el-col :span="6">采购员：{{form.admin_name}}</el-col>
                    <el-col :span="6">备注：{{form.remark}}</el-col>
                </el-row>
            </div>
            
            <div class="" style="margin-top: 10px; font-size: 14px;">
                <div style="padding-bottom: 20px;">采购产品：</div>
                <div class="">
                    <el-table :data="form.purchase_plan_product" style="width: 100%" >
                      <el-table-column label="产品编号">
                          <template v-slot="scope">
                            {{scope.row.product.product_no}}
                          </template>
                      </el-table-column>
                      <el-table-column label="品名">
                          <template v-slot="scope">
                            {{scope.row.product.product_name}}
                          </template>
                      </el-table-column>
                      <el-table-column label="型号规格">
                          <template v-slot="scope">
                            {{scope.row.product.model}}
                          </template>
                      </el-table-column>
                      <el-table-column label="生产厂家">
                          <template v-slot="scope">
                            {{scope.row.product.manufacturer}}
                          </template>
                      </el-table-column>
                      <el-table-column label="包装单位">
                          <template v-slot="scope">
                            {{scope.row.pack_unit_name}}
                          </template>
                      </el-table-column>
                      <el-table-column label="数量">
                          <template v-slot="scope">
                            {{scope.row.product_num}}
                          </template>
                      </el-table-column>
                      <el-table-column label="单价">
                          <template v-slot="scope">
                            {{scope.row.product_price}}
                          </template>
                      </el-table-column>
                      <el-table-column label="金额">
                          <template v-slot="scope">
                            {{scope.row.amount}}
                          </template>
                      </el-table-column>
                      <!-- <el-table-column label="批号生产日期">
                          <template v-slot="scope">
                            {{scope.row.batch_number}}
                          </template>
                      </el-table-column> -->
                      <!-- <el-table-column label="有效期至">
                          <template v-slot="scope">
                            {{scope.row.valid_until || '长效期'}}
                          </template>
                      </el-table-column> -->
                      <el-table-column label="注册证名">
                          <template v-slot="scope">
                            {{scope.row.product.register_cert_name}}
                          </template>
                      </el-table-column>
                      <el-table-column label="注册证号">
                          <template v-slot="scope">
                            {{scope.row.product.register_cert_no}}
                          </template>
                      </el-table-column>
                      
                    </el-table>
                </div>
            </div>
            
            <div class="bottom_btn" style="justify-content: flex-start;">
                <el-button @click="$router.go(-1)">返回</el-button>
            </div>
        </el-card>
        <!-- 上传图片预览 -->
        <el-dialog :close-on-click-modal="false" title="图片预览" width="600px" v-model="uploadImgVisible">
            <img style="width: 100%;" :src="uploadImageUrl" alt="">
        </el-dialog>
    </div>
</template>

<script>
    // 上传组件
    export default {
        name: "productDetail",
        data() {
            return {
                purchase_plan:'',
                form: '',
                // 上传图片
                uploadImageUrl: '',
                uploadImgVisible: false,
            };
        },
        created() {
            this.id = this.$route.query.id;
            this.get_data()
        },
        methods: {
            PicturePreview(file) {
                this.uploadImageUrl = file.url;
                this.uploadImgVisible = true;
            },
            get_data() {
                this.$httpGet("/backend/PurchasePlan/read", {
                    id: this.id
                }).then((res) => {
                    if (res.status == 200) {
                        let _data = res.data;
                        this.form = _data;
                    } else {
                        this.$message.error(res.message);
                    }
                }).catch((err) => {
                    console.log(err);
                });
            },
        },
    };
</script>

<style lang="scss" scoped>
    .items {
        width: 100%;
        padding-right: 30px;
    }

    .items .item {
        padding: 10px;
        width: 100%;
        padding-right: 30px;
        line-height: 24px;
        color: #333;
        font-size: 14px;
    }

    .items .item .tt {
        color: #888;
        width: 150px
    }

    .avatar {
        height: 80px;
    }
</style>
